import "./App.scss";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { initializeApp } from "firebase/app";
import {
    addDoc,
    collection,
    getFirestore,
    Timestamp,
} from "firebase/firestore";
import Swal from "sweetalert2";

const firebaseConfig = {
    apiKey: "AIzaSyBfbAsIu95ZZ0WfCSKdV6vpZi3YKhzeIrA",
    authDomain: "mschurch-d0718.firebaseapp.com",
    databaseURL: "https://mschurch-d0718.firebaseio.com",
    projectId: "mschurch-d0718",
    storageBucket: "mschurch-d0718.appspot.com",
    messagingSenderId: "30686028904",
    appId: "1:30686028904:web:152f6e7114cdac331187dd",
    measurementId: "G-9DFHPR9SKV",
};
// init app
const app = initializeApp(firebaseConfig);
const fdb = getFirestore(app);

function App() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const query = new URLSearchParams(useLocation().search);
    let churchId = query.get("church");
    if (churchId === null) {
        churchId = "";
    }
    let service = query.get("service");
    if (service === null) {
        service = "";
    }

    const resetForm = () => {
        Array.from(document.querySelectorAll("input")).forEach((input) => {
            if (input.name !== "service") {
                input.value = "";
            }
        });
    };

    const submit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (isSubmitting) {
            return;
        }
        const formData = new FormData(e.currentTarget);
        var data: any = {};
        for (let [key, value] of formData.entries()) {
            data[key] = value;
        }
        data.name = data.name.trim().toUpperCase();
        data.phone_number = data.phone_number.trim();
        data.source = "altar call";
        data.created_at = Timestamp.now();
        try {
            setIsSubmitting(true);
            await addDoc(collection(fdb, "altar_calls"), data);
            Swal.fire("Berhasil", "Data Anda berhasil disimpan.", "success");
            resetForm();
        } catch (exc) {
            console.error("Error adding document: ", e);
            Swal.fire(
                "Error",
                "Terjadi kesalahan saat memproses data Anda.",
                "error"
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div id="mainAltarCall">
            <div className="header mb-3">
                <div className="container">
                    <h1>
                        <img
                            className="logo"
                            src="https://gmschurch-assets-2.b-cdn.net/gms-v2-white.png"
                            alt="logo"
                        />{" "}
                        Altar Call
                    </h1>
                </div>
            </div>
            <div className="container">
                <div id="formWrapper">
                    <Form onSubmit={submit}>
                        <div className="field-wrapper mb-3">
                            <div className="mb-3">
                                <div>Silahkan isi form ini</div>
                            </div>
                            <Form.Group className="mb-3" controlId="formName">
                                <Form.Label>Nama</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    name="name"
                                    maxLength={100}
                                    required
                                />
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="formPhoneNumber"
                            >
                                <Form.Label>No. HP</Form.Label>
                                <Form.Control
                                    type="tel"
                                    placeholder=""
                                    name="phone_number"
                                    maxLength={16}
                                    required
                                />
                            </Form.Group>
                            {/* <Form.Group className="mb-3" controlId="formChurch">
                <Form.Label>GMS</Form.Label>
                <Form.Select name="church" defaultValue={churchId}>
                  {churches.map((church: any) => {
                    return <option key={`option-church-${church.church_id}`} value={church.church_id}>{church.name}</option>;
                  })}
                </Form.Select>
              </Form.Group> */}
                            {/* <Form.Group className="mb-3" controlId="formService">
                <Form.Label>Ibadah</Form.Label>
                <Form.Select name="service" required defaultValue={service}>
                  <option value=''>-- Pilih Ibadah --</option>
                  <option value='Ibadah 1'>Ibadah 1</option>
                  <option value='Ibadah 2'>Ibadah 2</option>
                  <option value='Ibadah 3'>Ibadah 3</option>
                  <option value='Ibadah 4'>Ibadah 4</option>
                  <option value='Ibadah 5'>Ibadah 5</option>
                  <option value='AOG Teen'>AOG Teen</option>
                  <option value='AOG Youth'>AOG Youth</option>
                </Form.Select>
              </Form.Group> */}
                        </div>
                        <div className="mb-3">
                            <button
                                id="btnSubmitFormAltarCall"
                                className="btn btn-success w-100"
                                disabled={isSubmitting ? true : false}
                            >
                                SUBMIT
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default App;
